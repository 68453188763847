import React from "react";
import { Footer, Navbar } from "../components";
import PartnershipSection from "../components/PartnershipSection";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        {/* Hero Section */}
        <section
          className="hero-section text-center py-5"
          style={{
            backgroundImage: "url('/images/banner.jpg')",
            backgroundSize: "cover",
            height: "600px",
            color: "white",
            position: "relative",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>
          <div style={{ position: "relative", zIndex: 1 }}>
            <h1>Empowering Your Business Travel</h1>
            <p>
              Comprehensive corporate travel and meetings services, customized
              for your business. Anytime, anywhere.
            </p>
            <Link to="/contact" className="btn btn-warning">
              Let's Talk
            </Link>
          </div>
        </section>

        {/* Global Business Travel Forecast */}
        <section className="forecast-section py-5 text-center">
          <h2>2025 Global Business Travel Forecast</h2>
          <p>
            Stay ahead with insights on pricing for air, hotel, and ground
            transportation to manage your travel program effectively.
          </p>
          <Link to="/contact" className="btn btn-warning">
            Get more information
          </Link>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section py-5">
          <div className="row text-center">
            <div className="col-md-4 py-3">
              <h4>For Your Employees</h4>
              <p>
                Keep your team connected and productive with seamless travel
                solutions.
              </p>
            </div>
            <div className="col-md-4 py-3">
              <h4>For Your Organization</h4>
              <p>Maximize the ROI of your travel and events programs.</p>
            </div>
            <div className="col-md-4 py-3">
              <h4>For the Future</h4>
              <p>
                Embrace innovation and adapt to the evolving industry landscape.
              </p>
            </div>
          </div>
        </section>

        {/* Client Logos */}
        <section className="client-logos py-5 bg-light text-black text-center">
          <h2>Trusted by Leading Companies Worldwide</h2>
          <div className="row my-4">
            <div className="col-md-3">
              <img src="/images/dd_logo.svg" alt="Company 1" width="150px" />
            </div>
            <div className="col-md-3">
              <img
                src="/images/mongodb-logo.svg"
                alt="Company 2"
                width="150px"
              />
            </div>
            <div className="col-md-3">
              <img
                src="/images/nilfisk-logo.webp"
                alt="Company 3"
                width="150px"
              />
            </div>
            <div className="col-md-3">
              <img
                src="/images/Primark_Stores_Logo.webp"
                alt="Company 4"
                width="150px"
              />
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-3">
              <img
                src="/images/lyft-color.webp"
                alt="Company 5"
                width="150px"
              />
            </div>
            <div className="col-md-3">
              <img
                src="/images/shaw-floors-vector.svg"
                alt="Company 6"
                width="150px"
              />
            </div>
            <div className="col-md-3">
              <img
                src="/images/snowflake-logo.svg"
                alt="Company 7"
                width="150px"
              />
            </div>
            <div className="col-md-3">
              <img src="/images/yelp_logo.webp" alt="Company 8" width="150px" />
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="features-section py-5">
          <h2 className="text-center">Key Features of Our Service</h2>
          <div className="row text-center">
            <div className="col-md-4">
              <img
                src="/images/easy.png"
                alt="Travel Made Easy"
                className="py-3"
                width="90px"
              />
              <h4>Travel Made Easy</h4>
              <p>Streamline bookings and keep your team on the move.</p>
            </div>
            <div className="col-md-4">
              <img
                src="/images/driven.png"
                alt="Data-Driven Insights"
                className="py-3"
                width="90px"
              />
              <h4>Data-Driven Insights</h4>
              <p>Turn your travel data into actionable strategies.</p>
            </div>
            <div className="col-md-4">
              <img
                src="/images/support.png"
                alt="Global Support"
                className="py-3"
                width="90px"
              />
              <h4>Global Support</h4>
              <p>Get 24/7 support no matter where you are in the world.</p>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section py-5 text-center text-white bg-dark">
          <h2>What Our Clients Say</h2>
          <p>
            "Thanks to our partnership, we've significantly improved our travel
            management." - CEO, Global Corp
          </p>
          <p>
            "Their travel solutions are a game-changer for our team." - HR
            Manager, Tech Company
          </p>
        </section>

        {/* CTA Section */}
        <section className="cta-section text-center py-5 bg-light">
          <h2>Ready to Transform Your Business Travel?</h2>
          <p>
            Reach out today and discover how we can elevate your corporate
            travel experience.
          </p>
          <Link to="/contact" className="btn btn-warning">
            Get in Touch
          </Link>
        </section>
      </div>
      <PartnershipSection />
      <Footer />
    </>
  );
};

export default HomePage;
