import React from "react";
import { NavLink } from "react-router-dom";

const Solutions = () => {
  return (
    <div className="our-technology">
      {/* Hero section */}
      <section className="hero bg-dark text-light py-5">
        <div className="container text-center">
          <h1 className="display-4 fw-bold">
            Empowering People, Expanding Technology
          </h1>
          <p className="lead">
            Unlock the future of travel management with innovative solutions
            that make business travel more seamless and efficient.
          </p>
        </div>
      </section>

      {/* Block 1: Текст слева, картинка справа */}
      <section className="technology-section py-5">
        <div className="container d-flex align-items-center">
          <div className="col-md-6">
            <h2 className="fw-bold text-primary">
              Streamline Your Business Travel
            </h2>
            <p>
              Discover the power of our comprehensive platform, mycwwt. Whether
              your employees book their travel online, through our mobile app,
              or via a travel counselor, all their travel details are instantly
              synchronized. Our system provides seamless access across all
              devices, ensuring convenience and efficiency in every step of
              their journey.
            </p>
            <NavLink to="/contact" className="btn btn-warning">
              Learn More
            </NavLink>
          </div>
          <div className="col-md-6">
            <img
              src="/images/technology1.jpg"
              alt="Travel Platform"
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
      </section>

      {/* Block 2: Картинка слева, текст справа */}
      <section className="technology-section py-5 bg-light">
        <div className="container d-flex align-items-center">
          <div className="col-md-6">
            <img
              src="/images/technology2.jpg"
              alt="Real-Time Data"
              className="img-fluid rounded shadow"
            />
          </div>
          <div className="col-md-6">
            <h2 className="fw-bold text-primary">
              Real-Time Insights, Better Decisions
            </h2>
            <p>
              Gain instant access to critical data with our real-time reporting
              tools. No more waiting for delayed reports – with mycwwt, you'll
              have the insights you need at your fingertips. From travel
              spending trends to cost-saving opportunities, our platform helps
              you make informed decisions faster.
            </p>
            <NavLink to="/contact" className="btn btn-warning">
              Explore Now
            </NavLink>
          </div>
        </div>
      </section>

      {/* Block 3: Текст слева, картинка справа */}
      <section className="technology-section py-5">
        <div className="container d-flex align-items-center">
          <div className="col-md-6">
            <h2 className="fw-bold text-primary">
              24/7 Support Anywhere in the World
            </h2>
            <p>
              Your employees can stay connected with our 24/7 chat service.
              Whether they need assistance with bookings or travel updates, our
              dedicated counselors are just a message away. With our intuitive
              platform, employees can reach out via chat on the web, in-app, or
              even through third-party messaging services.
            </p>
            <NavLink to="/contact" className="btn btn-warning">
              Get Support
            </NavLink>
          </div>
          <div className="col-md-6">
            <img
              src="/images/technology3.jpg"
              alt="24/7 Support"
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
      </section>

      {/* Block 4: Картинка слева, текст справа */}
      <section className="technology-section py-5 bg-light">
        <div className="container d-flex align-items-center">
          <div className="col-md-6">
            <img
              src="/images/technology4.jpg"
              alt="Future of Travel"
              className="img-fluid rounded shadow"
            />
          </div>
          <div className="col-md-6">
            <h2 className="fw-bold text-primary">
              The Future of Travel Technology
            </h2>
            <p>
              From biometric check-ins to voice-activated hotel rooms, the
              future of travel is closer than you think. We’re continuously
              evolving to integrate the latest technologies that make your
              travel experience more personalized and efficient. At mycwwt, we’re
              committed to driving the innovation that will shape the next era
              of business travel.
            </p>
            <NavLink to="/contact" className="btn btn-warning">
              Learn About the Future
            </NavLink>
          </div>
        </div>
      </section>

      {/* Block 5: Текст слева, картинка справа */}
      <section className="technology-section py-5">
        <div className="container d-flex align-items-center">
          <div className="col-md-6">
            <h2 className="fw-bold text-primary">
              Sustainability Meets Innovation
            </h2>
            <p>
              We believe in more sustainable travel solutions. With our
              commitment to reducing carbon footprints through technology and
              responsible travel practices, we help your business travel align
              with environmental goals. Whether it’s electric vehicles, virtual
              meetings, or smart itineraries, we offer solutions for a more
              sustainable travel future.
            </p>
            <NavLink to="/contact" className="btn btn-warning">
              Start a Sustainable Journey
            </NavLink>
          </div>
          <div className="col-md-6">
            <img
              src="/images/technology5.jpg"
              alt="Sustainability"
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
      </section>

      {/* Conclusion */}
      <section className="conclusion-section py-5 bg-dark text-light">
        <div className="container text-center">
          <h2 className="fw-bold">Ready to Transform Your Business Travel?</h2>
          <p className="lead">
            Let our technology take your travel management to the next level.
          </p>
          <NavLink to="/contact" className="btn btn-warning">
            Let's Talk
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default Solutions;
