import React from "react";

const Advantages = () => {
  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h2 style={styles.heading}>Why Choose Us</h2>
        <p style={styles.subheading}>
          Discover what makes our services stand out in the travel industry.
        </p>
        <div style={styles.row}>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Over a Decade of Excellence</h5>
            <p style={styles.cardDescription}>
              With over 10 years in the market, we’ve built a solid reputation
              for delivering top-notch travel experiences.
            </p>
          </div>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Trusted by 10,000+ Travelers</h5>
            <p style={styles.cardDescription}>
              Every year, thousands of clients choose us for seamless,
              worry-free travel planning.
            </p>
          </div>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Exclusive Access to Deals</h5>
            <p style={styles.cardDescription}>
              We partner with leading hotels, airlines, and tour operators to
              offer exclusive deals you won't find elsewhere.
            </p>
          </div>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Global Destination Coverage</h5>
            <p style={styles.cardDescription}>
              From tropical islands to bustling cities, our services span across
              the globe, ensuring you're covered wherever you go.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

// Стили для оформления компонента
const styles = {
  section: {
    padding: "60px 0",
    backgroundColor: "#f4f7fa",
    textAlign: "center",
  },
  container: {
    maxWidth: "1100px",
    margin: "0 auto",
    padding: "0 20px",
  },
  heading: {
    fontSize: "2.8rem",
    color: "#333",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  subheading: {
    fontSize: "1.2rem",
    marginBottom: "40px",
    color: "#555",
    fontStyle: "italic",
  },
  row: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    margin: "15px",
    flex: "1 1 calc(25% - 30px)", // Ширина карточки 25% от доступного пространства
    maxWidth: "260px",
    transition: "transform 0.3s, box-shadow 0.3s",
    textAlign: "left",
  },
  cardTitle: {
    marginTop: "20px",
    fontSize: "1.6rem",
    color: "#007bff",
    fontWeight: "bold",
  },
  cardDescription: {
    margin: "15px 0",
    fontSize: "1rem",
    color: "#555",
    lineHeight: "1.7",
  },
};

// Экспорт компонента
export default Advantages;
