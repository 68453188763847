import React from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">About CWT</h1>
        <hr />

        {/* Introduction */}
        <section className="my-5">
          <h2 className="text-center py-4">Our Platform</h2>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <p>
                At CWT, we focus on B2B4E (Business-to-Business-for-Employees),
                providing the best possible experience for both businesses and
                their employees. Our goal is to simplify business travel by
                offering innovative tools and services that are tailored to the
                needs of modern travelers.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/images/our-platform.jpg"
                alt="Our Platform"
                className="img-fluid"
              />
            </div>
          </div>
        </section>

        {/* Our Mission */}
        <section className="my-5">
          <h2 className="text-center py-4">Our Mission</h2>
          <div className="row">
            <div className="col-md-6">
              <img
                src="/images/our-mission.jpg"
                alt="Our Mission"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <p>
                With a history spanning over a century, we are committed to
                constantly evolving to meet the changing needs of the business
                travel industry. Our mission is to deliver customer-focused
                solutions that enhance the overall experience for business
                travelers and their companies.
              </p>
            </div>
          </div>
        </section>

        {/* Meetings & Events */}
        <section className="my-5">
          <h2 className="text-center py-4">Meetings & Events</h2>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <p>
                CWT Meetings & Events (M&E) is an award-winning service that
                offers comprehensive live, virtual, and hybrid meeting solutions
                for our clients around the globe. Our M&E services help
                businesses manage large-scale events efficiently and with
                maximum impact.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/images/meetings-events.webp"
                alt="Meetings & Events"
                className="img-fluid"
              />
            </div>
          </div>
        </section>

        {/* Achievements */}
        <section className="my-5">
          <h2 className="text-center py-4">Our Achievements</h2>
          <div className="row">
            <div className="col-md-6">
              <p className="text-center">
                Over the past five years, CWT has been awarded three gold and
                two platinum EcoVadis awards for our Environmental, Social, and
                Governance (ESG) approach. This recognition highlights our
                commitment to sustainability and ethical business practices.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/images/achievements.jpg"
                alt="Achievements"
                className="img-fluid"
              />
            </div>
          </div>
        </section>

        {/* Key Facts */}
        <section className="my-5">
          <h2 className="text-center py-4">Key Facts & Figures</h2>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <p>
                We serve clients in over 220 countries, providing them with
                consumer-grade travel experiences through a combination of
                innovative technology and our vast industry expertise. Our
                platform connects people, places, and experiences around the
                world.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="/images/key-facts.webp"
                alt="Key Facts"
                className="img-fluid"
              />
            </div>
          </div>
        </section>

        {/* Join Our Team */}
        <section className="my-5">
          <h2 className="text-center py-4">Join Our Team</h2>
          <p className="text-center">
            We are always looking for talented and driven individuals to join
            our diverse, global team. If you are passionate about travel and
            technology, we invite you to explore career opportunities at CWT and
            help us shape the future of corporate travel management.
          </p>
          <div className="text-center">
            <Link to="/contact" className="btn btn-primary">
              View Career Opportunities
            </Link>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
