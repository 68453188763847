import React from "react";
import { Footer, Navbar } from "../components";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Contact Us</h1>
        <hr />
        <p className="lead text-center">
          Interested in learning more about our corporate travel management
          services? Fill out the form below, and our team will get back to you
          shortly. For travelers, please manage your travel using our dedicated
          portal.
        </p>

        <div className="row my-4">
          <div className="col-md-6 col-lg-6 col-sm-8 mx-auto">
            <form>
              <div className="form-group my-3">
                <label htmlFor="firstName">First Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter your first name"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="lastName">Last Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter your last name"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="companyName">Company Name *</label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  placeholder="Enter your company name"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="jobTitle">Job Title *</label>
                <input
                  type="text"
                  className="form-control"
                  id="jobTitle"
                  placeholder="Enter your job title"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="location">Business Location *</label>
                <select className="form-control" id="location" required>
                  <option value="" disabled selected>
                    Select Location
                  </option>
                  <option value="US">United States</option>
                  <option value="UK">United Kingdom</option>
                  <option value="EU">Europe</option>
                  <option value="AS">Asia</option>
                  <option value="AU">Australia</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className="form-group my-3">
                <label htmlFor="phone">Business Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="email">Business Email *</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="postalCode">Business Postal Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="postalCode"
                  placeholder="Enter your postal code"
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="travelSpend">
                  Annual Travel Spend in USD *
                </label>
                <select className="form-control" id="travelSpend" required>
                  <option value="" disabled selected>
                    Select Range
                  </option>
                  <option value="0-50000">0 - 50,000</option>
                  <option value="50000-100000">50,000 - 100,000</option>
                  <option value="100000-500000">100,000 - 500,000</option>
                  <option value="500000-1000000">500,000 - 1,000,000</option>
                  <option value="1000000+">1,000,000+</option>
                </select>
              </div>
              <div className="form-group my-3">
                <label htmlFor="interest">Area of Interest *</label>
                <select className="form-control" id="interest" required>
                  <option value="" disabled selected>
                    Select an option
                  </option>
                  <option value="travelManagement">
                    Corporate Travel Management
                  </option>
                  <option value="meetingsEvents">Meetings & Events</option>
                  <option value="technologySolutions">
                    Technology Solutions
                  </option>
                  <option value="consulting">Consulting Services</option>
                </select>
              </div>
              <div className="form-group my-3">
                <label htmlFor="message">Message</label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="message"
                  placeholder="Enter your message"
                />
              </div>
              <div className="text-center">
                <button
                  className="my-2 px-4 mx-auto btn btn-dark"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Contact Information */}
        <div className="row text-center mt-5">
          <div className="col-md-12">
            <h2>Contact Information</h2>
            <p>
              Prefer to reach out to us directly? Here are our contact details:
            </p>
            <ul className="list-unstyled">
              <li>
                <strong>Address:</strong> 8687 N Central Expy, Dallas, TX
                75225-4427 , United States
              </li>
              <li>
                <strong>Phone:</strong> +1 214-363-7441
              </li>
              <li>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@mycwwt.com">info@mycwwt.com</a>
              </li>
            </ul>
            <p>We look forward to hearing from you!</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
