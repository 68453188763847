import React from "react";
import { Link } from "react-router-dom";

const PartnershipSection = () => {
  return (
    <section className="partnership-section py-5 bg-light">
      <div className="container text-center">
        <h2 className="fw-bold mb-4">Partner with mycwwt</h2>
        <p className="mb-5">
          Join forces with mycwwt and let's grow together. Explore partnership
          opportunities designed to boost your business.
        </p>

        <div className="row">
          {/* Affiliate Partners */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="partner-card p-4 bg-white border rounded shadow-sm">
              <h5 className="fw-bold">Affiliate Partners</h5>
              <p className="text-muted">
                Seamlessly integrate our system into your platform to give users
                access to competitive booking rates. Contact us at: <br />
                <strong>affiliate@mycwwt.com</strong>
              </p>
              <Link to="/contact" className="btn btn-warning">
                Learn More
              </Link>
            </div>
          </div>

          {/* Suppliers */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="partner-card p-4 bg-white border rounded shadow-sm">
              <h5 className="fw-bold">Suppliers</h5>
              <p className="text-muted">
                Join our network of leading suppliers and expand your business.
                Contact: <br />
                <strong>suppliers@mycwwt.com</strong>
              </p>
              <Link to="/contact" className="btn btn-warning">
                Learn More
              </Link>
            </div>
          </div>

          {/* Hotels */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="partner-card p-4 bg-white border rounded shadow-sm">
              <h5 className="fw-bold">Hotels</h5>
              <p className="text-muted">
                Boost your hotel's direct bookings and brand presence with
                mycwwt. Reach us at: <br />
                <strong>hotels@mycwwt.com</strong>
              </p>
              <Link to="/contact" className="btn btn-warning">
                Learn More
              </Link>
            </div>
          </div>

          {/* Technology Providers */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="partner-card p-4 bg-white border rounded shadow-sm">
              <h5 className="fw-bold">Technology Providers</h5>
              <p className="text-muted">
                Integrate your tech solutions with mycwwt and grow your
                platform’s capabilities. Reach out to: <br />
                <strong>tech@mycwwt.com</strong>
              </p>
              <Link to="/contact" className="btn btn-warning">
                Learn More
              </Link>
            </div>
          </div>

          {/* Marketing and PR */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="partner-card p-4 bg-white border rounded shadow-sm">
              <h5 className="fw-bold">Marketing and PR</h5>
              <p className="text-muted">
                Let's collaborate on campaigns that drive client engagement.
                Contact us at: <br />
                <strong>pr@mycwwt.com</strong>
              </p>
              <Link to="/contact" className="btn btn-warning">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnershipSection;
