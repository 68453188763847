import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold">About mycwwt</h5>
            <p>
              At mycwwt, we tailor unforgettable travel experiences with a
              focus on comfort and adventure.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold">Explore</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/" className="text-decoration-none text-light">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="text-decoration-none text-light">
                  About Us
                </a>
              </li>
              <li>
                <a href="/technology" className="text-decoration-none text-light">
                  Our Technology
                </a>
              </li>
              <li>
                <a href="/contact" className="text-decoration-none text-light">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold">Connect with Us</h5>
            <div>
              <a
                className="text-light fs-3 me-3"
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                className="text-light fs-3"
                href="https://facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4 bg-light" />
        <div className="d-flex justify-content-center">
          <p className="mb-0">© 2024 mycwwt. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
