import React from "react";
import { Footer, Navbar, Product } from "../components";
import Solutions from "../components/Solutions";

const Products = () => {
  return (
    <>
      <Navbar />
      <Solutions />
      <Footer />
    </>
  );
};

export default Products;
